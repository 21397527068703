.degrees-input {
  width: 60px;
  box-sizing: border-box;
  border-radius: 4px;
  border: 1px solid #ccc;
  margin: 8px 0px 8px 4px;
  padding: 6px 10px;
  display: inline-block;
  font-family: Raleway, sans-serif;
  font-size: 18px;
}

.degrees-label {
  margin-right: 10px;
}