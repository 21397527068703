.tooltip {
  position: relative;
  display: inline-block;
}

/* Tooltip text */
.tooltip .tooltiptext {
  visibility: hidden;
  width: 0px;
  background-color: #444;
  color: #fff;
  text-align: center;
  padding: 5px;
  border-radius: 6px;

  /* Position the tooltip text - see examples below! */
  position: absolute;
  z-index: 1;
}

/* Show the tooltip text when you mouse over the tooltip container */
.tooltip:hover .tooltiptext {
  visibility: visible;
  width: 300px;
  margin-left: -150px;
}

.tooltip-btn {
  background-color: white;
  margin-left: 13px;
  border: 1px solid #ccc;
  border-radius: 15px;
  text-decoration: none;
  height: 30px;
  width: 30px;
  color: #666;
  outline: 0;
}


