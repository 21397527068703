
.App-logo {
  height: 40vmin;
  font-size: 20px;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-orientation: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #09d3ac;
}


.header {
  text-align: center;
  line-height: 1;
  font-size: 40px;
  font-weight: normal;
}

.button {
  background: white;
  box-sizing: border-box;
  border-radius: 4px;
  border: 1px solid #ccc;
  margin: 8px 4px;
  padding: 6px 10px;
  display: inline-block;
  font-family: Raleway, sans-serif;
  font-size: 18px;
}

.blue-button {
  background: white;
  box-sizing: border-box;
  border-radius: 4px;
  border: 1px solid #ccc;
  margin: 8px 4px;
  padding: 6px 10px;
  display: inline-block;
  font-family: Raleway, sans-serif;
  font-size: 18px;
}

.calc-time {
  font-size: 16px;
  font-style: italic;
}

.lat-link {
  margin-left: 10px;
  text-decoration: none;
  font-style: italic;
  color: #333;
  cursor: pointer;
  display: inline-block;
}