.display-header {
  font-weight: normal;
  text-align: center;
}

table {
  border-collapse: collapse;
  margin: 0 auto;
}

 td {
  border: 1px solid #ccc;
}

th, td {
  padding: 4px 6px;
}

.calculating {
  text-align: center;
  font-style: italic;
}

.all-months-wrapper {
  display: flex;
  flex-flow: row wrap;
  width: 100%;
}
.month-wrapper {
  flex: 1 22%;
  padding: 12px;
  border: 1px solid #ccc;
  border-radius: 4px;
  min-width: 70px;
}
.month-wrapper {
  margin: 5px;
  box-sizing: border-box;
}
@media all and (min-width: 400px) {
  .month-wrapper {
    flex: 1 1 20%;
  }
}
@media all and (min-width: 700px) {
  .month-wrapper {
    flex: 1 1 12%;
    min-width: 100px;
  }
}
@media all and (min-width: 840px) {
  .month-wrapper {
    flex: 1 1 12%;
    min-width: 120px;
  }
}
@media all and (min-width: 970px) {
  .month-wrapper {
    flex: 1 1 12%;
    min-width: 140px;
  }
}
@media all and (min-width: 1050px) {
  .month-wrapper {
    flex: 1 1 12%;
    min-width: 160px;
  }
}

@media all and (min-width: 1230px) {
  .month-wrapper {
    flex: 1 1 0;
    min-width: 70px;
  }
}



.month-label {
  display: block;
  font-weight: bold;
  text-decoration: underline;
}

.month-data {
  display: block;
  flex: 1 0 0;
}


.month-total {
  font-weight: bold;
}